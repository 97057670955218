import React, { useMemo, useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../../providers/store/use-stores';
import { calculateStarRadius } from '../../../utils';

const interpolateColor = (color1: number[], color2: number[], factor: number): number[] => {
  const result = color1.slice();
  for (let i = 0; i < 3; i++) {
    result[i] = Math.round(result[i] + factor * (color2[i] - color1[i]));
  }
  return result;
};

const getStarColor = (mass: number): number[] => {
  const colorRanges = [
    { range: [0, 0.5], color: [255, 100, 80] },
    { range: [0.5, 0.75], color: [218, 165, 32] },
    { range: [0.75, 1], color: [255, 215, 0] },
    { range: [1, 1.5], color: [255, 250, 205] },
    { range: [1.5, 3], color: [255, 255, 255] },
    { range: [3, 15], color: [135, 206, 250] },
    { range: [15, 20], color: [30, 144, 255] },
  ];

  for (let i = 0; i < colorRanges.length; i++) {
    const { range, color } = colorRanges[i];
    if (mass >= range[0] && mass <= range[1]) {
      if (i === 0) return color;
      const previousColor = colorRanges[i - 1].color;
      const factor = (mass - range[0]) / (range[1] - range[0]);
      return interpolateColor(previousColor, color, factor);
    }
  }
  return [0, 0, 255];
};

const GLOBAL_SCALE_FACTOR = 1.15;
const BASE_WIDTH = 1920;
const BASE_HEIGHT = 1080;
const SVG_WIDTH = BASE_WIDTH * GLOBAL_SCALE_FACTOR;
const SVG_HEIGHT = BASE_HEIGHT * GLOBAL_SCALE_FACTOR;
const CENTER_X = SVG_WIDTH / 2;
const CENTER_Y = SVG_HEIGHT / 2;

const BASE_STAR_RADIUS = 20 * GLOBAL_SCALE_FACTOR;

const ORBIT_RADIUS_X = 230 * GLOBAL_SCALE_FACTOR;
const ORBIT_RADIUS_Y = 230 * GLOBAL_SCALE_FACTOR;

const StarMassView = observer(() => {
  const {
    appStore: { starMassEnabled, starMassValue: starMass, setStarMassValue: setStarMass },
  } = useStores();

  const [orbitAngle, setOrbitAngle] = useState(0);
  const starColor = getStarColor(starMass);

  const starRadius = useMemo(() => {
    const calculatedRadius = calculateStarRadius(starMass);
    return BASE_STAR_RADIUS * calculatedRadius;
  }, [starMass]);

  const planetRadius = BASE_STAR_RADIUS * 0.3;

  useEffect(() => {
    const interval = setInterval(() => {
      const angularSpeed = Math.sqrt(starMass || 0.1);
      setOrbitAngle((prevAngle) => (prevAngle + angularSpeed * 0.5) % 360);
    }, 16);
    return () => clearInterval(interval);
  }, [starMass]);

  const planetX = CENTER_X + ORBIT_RADIUS_X * Math.cos((orbitAngle * Math.PI) / 180);
  const planetY = CENTER_Y + ORBIT_RADIUS_Y * Math.sin((orbitAngle * Math.PI) / 180);

  return (
    <div className="flex flex-1 w-full justify-center items-center">
      <div className="flex flex-col items-center">
        <svg width={SVG_WIDTH} height={SVG_HEIGHT} style={{ position: 'relative' }}>
          {/* Shadow for the star */}
          <circle cx={CENTER_X} cy={CENTER_Y} r={starRadius * 1.05} fill="rgba(0, 0, 0, 0.5)" />

          {/* Star */}
          <circle
            cx={CENTER_X}
            cy={CENTER_Y}
            r={starRadius}
            fill={`rgb(${starColor[0]}, ${starColor[1]}, ${starColor[2]})`}
            style={{ transition: 'fill 0.1s ease' }}
          />

          {/* Orbit */}
          <ellipse
            cx={CENTER_X}
            cy={CENTER_Y}
            rx={ORBIT_RADIUS_X}
            ry={ORBIT_RADIUS_Y}
            fill="none"
            stroke="rgba(255, 255, 255, 0.7)"
            strokeWidth="1"
          />

          {/* Planet */}
          <circle cx={planetX} cy={planetY} r={planetRadius} fill="lightblue" />

          {/* Glow effect */}
          <circle cx={CENTER_X} cy={CENTER_Y} r={2.5 * starRadius} fill="url(#starGlow)" />
          <defs>
            <radialGradient id="starGlow">
              <stop
                offset="0.18"
                stopColor={`rgba(${starColor[0]}, ${starColor[1]}, ${starColor[2]}, 0.7)`}
              />
              <stop
                offset="1"
                stopColor={`rgba(${starColor[0]}, ${starColor[1]}, ${starColor[2]}, 0.7)`}
                stopOpacity="0"
              />
            </radialGradient>
          </defs>
        </svg>
        <div className="absolute bottom-[15%]">
          <div
            className="my-4 h-[1px] w-[75vw]"
            style={{
              background:
                'linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0) 100%)',
            }}
          />

          {/* Star mass controls */}
          <div className="flex items-center justify-center text-white mt-4">
            <span className="text-[18px] mr-2">Star mass</span>
            <div className="flex items-center px-2 py-1" style={{ background: 'rgba(255, 255, 255, 0.15)' }}>
              <span className="mr-2">0</span>
              <input
                type="range"
                min="0.1"
                max="20"
                step="0.1"
                value={starMass}
                onChange={(e) => setStarMass(Number(e.target.value))}
                style={{
                  width: 150 * GLOBAL_SCALE_FACTOR,
                }}
                className="position-relative h-1 bg-gray-200 rounded-lg cursor-pointer appearance-none"
                disabled={!starMassEnabled}
              />
              <span className="ml-2">20</span>
            </div>
            <input
              type="number"
              min="0.1"
              max="20"
              step="0.1"
              value={starMass}
              onChange={(e) => setStarMass(Number(e.target.value))}
              className="w-12 h-8 px-2 border border-gray-300 rounded-lg ml-2 text-black"
              disabled={!starMassEnabled}
            />
            <span className="ml-2">
              M<sub>s</sub>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
});

export default StarMassView;
