import { action, makeAutoObservable, makeObservable, observable, reaction } from 'mobx';
import { ICapiModel } from '../capi';
import { CapiBoundStore, ICAPI } from 'asu-sim-toolkit';
import { CapiPlanet, CapiPlanetArray, CapiPlanetGraphArray } from './domain';
import { IGraphStore } from './types';
import { Planet } from './planet-store';
import { DraggableItem } from './dnd-store';

export class GraphStore extends CapiBoundStore<ICapiModel> implements IGraphStore {
  matchEnabled = false;
  graphEnabled: CapiPlanetGraphArray = [];
  graphCorrect: CapiPlanetArray = [];

  measurePeriodEnabled = false;

  graphMercuryPlanet = CapiPlanet.None;
  graphVenusPlanet = CapiPlanet.None;
  graphEarthPlanet = CapiPlanet.None;
  graphMarsPlanet = CapiPlanet.None;
  graphJupiterPlanet = CapiPlanet.None;
  graphSaturnPlanet = CapiPlanet.None;
  graphUranusPlanet = CapiPlanet.None;
  graphNeptunePlanet = CapiPlanet.None;
  graphExoBPlanet = CapiPlanet.None;
  graphExoCPlanet = CapiPlanet.None;

  selectedGraph: DraggableItem | null = null;

  constructor(capi: ICAPI<ICapiModel>) {
    super(capi);

    makeObservable(this, {
      matchEnabled: observable,
      graphEnabled: observable,
      graphCorrect: observable,
      setGraphCorrect: action.bound,

      measurePeriodEnabled: observable,

      graphMercuryPlanet: observable,
      graphVenusPlanet: observable,
      graphEarthPlanet: observable,
      graphMarsPlanet: observable,
      graphJupiterPlanet: observable,
      graphSaturnPlanet: observable,
      graphUranusPlanet: observable,
      graphNeptunePlanet: observable,
      graphExoBPlanet: observable,
      graphExoCPlanet: observable,

      setGraphMercuryPlanet: action.bound,
      setGraphVenusPlanet: action.bound,
      setGraphEarthPlanet: action.bound,
      setGraphMarsPlanet: action.bound,
      setGraphJupiterPlanet: action.bound,
      setGraphSaturnPlanet: action.bound,
      setGraphUranusPlanet: action.bound,
      setGraphNeptunePlanet: action.bound,
      setGraphExoBPlanet: action.bound,
      setGraphExoCPlanet: action.bound,

      selectedGraph: observable,
      setSelectedGraph: action.bound,
    });

    this.bindToCapi('matchEnabled', 'Sim.graphMatch.matchEnabled');
    this.bindToCapi('graphEnabled', 'Sim.graphMatch.enabled');
    this.bindToCapi('graphCorrect', 'Sim.graphMatch.correct');

    this.bindToCapi('measurePeriodEnabled', 'Sim.measurePeriod.enabled');

    this.bindToCapi('graphMercuryPlanet', 'Sim.graphMatch.graphMercury.planet');
    this.bindToCapi('graphVenusPlanet', 'Sim.graphMatch.graphVenus.planet');
    this.bindToCapi('graphEarthPlanet', 'Sim.graphMatch.graphEarth.planet');
    this.bindToCapi('graphMarsPlanet', 'Sim.graphMatch.graphMars.planet');
    this.bindToCapi('graphJupiterPlanet', 'Sim.graphMatch.graphJupiter.planet');
    this.bindToCapi('graphSaturnPlanet', 'Sim.graphMatch.graphSaturn.planet');
    this.bindToCapi('graphUranusPlanet', 'Sim.graphMatch.graphUranus.planet');
    this.bindToCapi('graphNeptunePlanet', 'Sim.graphMatch.graphNeptune.planet');
    this.bindToCapi('graphExoBPlanet', 'Sim.graphMatch.graphExoB.planet');
    this.bindToCapi('graphExoCPlanet', 'Sim.graphMatch.graphExoC.planet');
  }

  setSelectedGraph(item: DraggableItem | null) {
    this.selectedGraph = item;
  }

  setGraphCorrect(planet: CapiPlanet) {
    this.graphCorrect = [...this.graphCorrect, planet];
  }

  setGraphIncorrect(planet: CapiPlanet) {
    this.graphCorrect = this.graphCorrect.filter((p) => p !== planet);
  }

  resetGraphPlanets() {
    this.setGraphMercuryPlanet(CapiPlanet.None);
    this.setGraphVenusPlanet(CapiPlanet.None);
    this.setGraphEarthPlanet(CapiPlanet.None);
    this.setGraphMarsPlanet(CapiPlanet.None);
    this.setGraphJupiterPlanet(CapiPlanet.None);
    this.setGraphSaturnPlanet(CapiPlanet.None);
    this.setGraphNeptunePlanet(CapiPlanet.None);
    this.setGraphUranusPlanet(CapiPlanet.None);
    this.setGraphExoBPlanet(CapiPlanet.None);
    this.setGraphExoCPlanet(CapiPlanet.None);
  }

  setGraphPlanet(planet: Planet, drop?: boolean) {
    const name = drop ? CapiPlanet.None : planet.graph.droppedItem[0].planet.name;

    switch (planet.name) {
      case 'Mercury':
        this.setGraphMercuryPlanet(name);
        break;
      case 'Venus':
        this.setGraphVenusPlanet(name);
        break;
      case 'Earth':
        this.setGraphEarthPlanet(name);
        break;
      case 'Mars':
        this.setGraphMarsPlanet(name);
        break;
      case 'Jupiter':
        this.setGraphJupiterPlanet(name);
        break;
      case 'Saturn':
        this.setGraphSaturnPlanet(name);
        break;
      case 'Uranus':
        this.setGraphUranusPlanet(name);
        break;
      case 'Neptune':
        this.setGraphNeptunePlanet(name);
        break;
      case 'ExoB':
        this.setGraphExoBPlanet(name);
        break;
      case 'ExoC':
        this.setGraphExoCPlanet(name);
        break;
      default:
        break;
    }
    // if (planet.name === name) return this.setGraphCorrect(planet.name);
    // this.setGraphIncorrect(planet.name);
  }

  setGraphMercuryPlanet(capiPlanet: CapiPlanet) {
    this.graphMercuryPlanet = capiPlanet;
  }
  setGraphVenusPlanet(capiPlanet: CapiPlanet) {
    this.graphVenusPlanet = capiPlanet;
  }
  setGraphEarthPlanet(capiPlanet: CapiPlanet) {
    this.graphEarthPlanet = capiPlanet;
  }
  setGraphMarsPlanet(capiPlanet: CapiPlanet) {
    this.graphMarsPlanet = capiPlanet;
  }
  setGraphJupiterPlanet(capiPlanet: CapiPlanet) {
    this.graphJupiterPlanet = capiPlanet;
  }
  setGraphSaturnPlanet(capiPlanet: CapiPlanet) {
    this.graphSaturnPlanet = capiPlanet;
  }
  setGraphUranusPlanet(capiPlanet: CapiPlanet) {
    this.graphUranusPlanet = capiPlanet;
  }
  setGraphNeptunePlanet(capiPlanet: CapiPlanet) {
    this.graphNeptunePlanet = capiPlanet;
  }
  setGraphExoBPlanet(capiPlanet: CapiPlanet) {
    this.graphExoBPlanet = capiPlanet;
  }
  setGraphExoCPlanet(capiPlanet: CapiPlanet) {
    this.graphExoBPlanet = capiPlanet;
  }
}

export interface DataPoint {
  name: string;
  value: number | null;
}

const BASE_VALUE = 4;
const DIP_VALUE = 1;
const MAX_TIME = 48;
const MAX_POINTS = 250;

export class Graph {
  id: number;
  isEnabled = false;
  data: DataPoint[] = Array(MAX_POINTS).fill({ name: '0s', value: null });
  time = 0;
  playDingBuffer: number[] = [];
  planet: Planet;
  droppedItem: DraggableItem[] = [];

  constructor(planet: Planet, id: number) {
    this.planet = planet;
    this.id = id;

    makeAutoObservable(this);

    reaction(
      () => this.planet.playDing,
      (ding) => {
        if (ding) {
          this.playDingBuffer.push(this.time);
        }
      }
    );

    reaction(
      () => [this.planet.isActive, this.isEnabled],
      ([isPlanetActive, isGraphEnabled]) => {
        if (isPlanetActive && isGraphEnabled && this.time === 0) {
          this.startTimeInterval();
        }
      }
    );
  }

  startTimeInterval() {
    setInterval(() => {
      this.updateTime();
    }, 100);
  }

  enable() {
    this.isEnabled = true;
  }

  disable() {
    this.isEnabled = false;
  }

  dropItem(item: DraggableItem) {
    this.droppedItem = [item];
  }

  removeItem() {
    this.droppedItem = [];
  }

  updateTime() {
    if (this.time >= MAX_TIME) return;

    this.time += 0.16;
    this.updateGraphData();
  }

  updateGraphData() {
    if (this.playDingBuffer.length > 0) {
      this.data = this.data.map((point) => {
        if (point.value === null && this.playDingBuffer.length > 0) {
          const dingTime = this.playDingBuffer.shift();
          if (!dingTime) return point;
          return { name: `${dingTime.toFixed(1)}s`, value: DIP_VALUE };
        }
        return point;
      });
    } else {
      const indexToUpdate = this.data.findIndex((point) => point.value === null);
      if (indexToUpdate !== -1) {
        this.data = [
          ...this.data.slice(0, indexToUpdate),
          { name: `${this.time.toFixed(1)}s`, value: BASE_VALUE },
          ...this.data.slice(indexToUpdate + 1),
        ];
      }
    }
  }
}
