/* eslint-disable react/no-unknown-property */
import React, { useRef } from 'react';
import { useFrame } from '@react-three/fiber';
import * as THREE from 'three';
import { PlanetIndicator, TriangleIndicator } from './Indicators';
import { Planet } from '../../../stores/planet-store';
import { observer } from 'mobx-react-lite';

export const PlanetComponent = observer(
  ({
    planet: { name, color, x, z, size, calculatePosition, indicator, indicatorLabel },
  }: {
    planet: Planet;
  }) => {
    const planetRef = useRef<THREE.Mesh>(null);
    const triangleRef = useRef<THREE.Mesh>(null);
    const planetIndicatorRef = useRef<THREE.Mesh>(null);

    useFrame(({ clock }) => {
      const t = clock.getElapsedTime();

      calculatePosition(t);

      if (planetRef.current) {
        planetRef.current.position.x = x;
        planetRef.current.position.z = z;
      }

      if (triangleRef.current && planetRef.current && indicator) {
        const verticalOffset = 0.5 * Math.sin(t * 3);
        triangleRef.current.position.set(
          planetRef.current.position.x,
          planetRef.current.position.y + 3 + verticalOffset,
          planetRef.current.position.z
        );
      }

      if (planetIndicatorRef.current && planetRef.current) {
        planetIndicatorRef.current.position.set(
          planetRef.current.position.x,
          planetRef.current.position.y + 4,
          planetRef.current.position.z
        );
      }
    });

    return (
      <>
        <mesh ref={planetRef}>
          <sphereGeometry args={[size, 32, 32]} />
          <meshStandardMaterial color={color} />
        </mesh>
        {indicator ? <TriangleIndicator ref={triangleRef} /> : null}
        <PlanetIndicator ref={planetIndicatorRef} name={name} label={indicatorLabel} />
        {/* <Ecliptic xRadius={radius * 15 + 5} zRadius={radius * 15 + 5} /> */}
      </>
    );
  }
);
