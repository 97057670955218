import React from 'react';
import { DotProps } from 'recharts';

interface CustomDotProps extends DotProps {
  isAnchored: boolean;
  isSelected: boolean;
  payload: any;
  handleKeyboardSelect: () => void;
}

const CustomDot = ({
  cx,
  cy,
  isAnchored,
  isSelected,
  payload: { value },
  handleKeyboardSelect,
}: CustomDotProps) => {
  const radius = isAnchored || isSelected ? 6 : 4;
  const gapRadius = radius + 10;
  const borderRadius = radius + 9;

  if (value === null) return <></>;

  return (
    <>
      {isAnchored || isSelected ? (
        <>
          <circle cx={cx} cy={cy} r={radius} fill="#FFD585" />
          <circle cx={cx} cy={cy} r={gapRadius} fill="rgba(255, 215, 85, 0.5)" />
          <circle cx={cx} cy={cy} r={borderRadius} fill="none" stroke="#FFD585" strokeWidth={2} />
        </>
      ) : (
        <circle
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') handleKeyboardSelect();
          }}
          tabIndex={value === 1 ? 1 : undefined}
          role="button"
          cx={cx}
          cy={cy}
          r={radius}
          fill="#ccc"
        />
      )}
    </>
  );
};

export default CustomDot;
