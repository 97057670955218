import React from 'react';
import { useDrag } from 'react-dnd';
import { observer } from 'mobx-react-lite';
import { getPlanetSvg } from './DropZone';
import { useStores } from '../../../providers/store/use-stores';
import { DraggableItem as DraggableItemProps } from '../../../stores/dnd-store';

const DraggableItem = observer((item: DraggableItemProps) => {
  const {
    graphStore: { matchEnabled, selectedGraph, setSelectedGraph },
  } = useStores();

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: 'circle',
      item: item,
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
      canDrag: matchEnabled,
    }),
    [matchEnabled]
  );

  return (
    <div
      ref={matchEnabled ? drag : null}
      style={{
        opacity: isDragging ? 0 : selectedGraph?.id === item.id ? 0.5 : 1,
        cursor: matchEnabled ? 'grab' : 'not-allowed',
        width: 40,
        height: 40,
      }}
      tabIndex={2}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') setSelectedGraph(item);
      }}
    >
      {getPlanetSvg(item.id, item.color, true)}
    </div>
  );
});

export default DraggableItem;
