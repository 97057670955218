/* eslint-disable react/display-name */
/* eslint-disable react/no-unknown-property */
import React from 'react';
import { useLoader } from '@react-three/fiber';
import * as THREE from 'three';
import { observer } from 'mobx-react-lite';

export const TriangleIndicator = React.forwardRef((_, ref) => {
  const vertices = new Float32Array([0, -1, 0, -1, 1, 0, 1, 1, 0]);

  const triangleGeometry = new THREE.BufferGeometry();
  triangleGeometry.setAttribute('position', new THREE.BufferAttribute(vertices, 3));

  return (
    <mesh ref={ref} geometry={triangleGeometry}>
      <meshBasicMaterial color="red" side={THREE.DoubleSide} />
    </mesh>
  );
});
TriangleIndicator.displayName = 'Triangle Indicator';

export const PlanetIndicator = observer(
  React.forwardRef(({ name, label }: { name: string; label: boolean }, ref) => {
    // Load the texture based on the `name` and `label` state
    const texture = useLoader(
      THREE.TextureLoader,
      `./assets/planet-indicators/${label ? 'label' : 'color'}/${name}.svg`
    );

    return (
      <mesh ref={ref} position={[0, 0, 30]}>
        <planeGeometry args={[3.15, 3.85]} />
        <meshBasicMaterial map={texture} transparent />
      </mesh>
    );
  })
);
PlanetIndicator.displayName = 'Planet Indicator';
